
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IAddress, IBusinessSectorDB, ICompanyDB, IGeoLocation, IUserDB, LabelValue } from "@/types";
import InlineField from "@/components/InlineField.vue";
import { capitalize, formattedAddress } from "@/helpers/commons";
import NumberTile from "@/components/tileContent/NumberTile.vue";
import { ROUTES } from "@/data";
import ContactLine from "@/components/ContactLine.vue";
import { Getter, State } from "vuex-class";
import ProfileContacts from "@/components/profileContexts/ProfileContacts.vue";
import ProfileDescription from "@/components/profileContexts/ProfileDescription.vue";
import ProfileMap from "@/components/profileContexts/ProfileMap.vue";
import ProfileVideo from "@/components/profileContexts/ProfileVideo.vue";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import Row from '../grid/Row.vue';
import Column from '../grid/Column.vue';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import Tag from '../Tag.vue';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
    name: "ClientRead",
    components: {
        MissionTile,
        ProfileVideo, ProfileMap, ProfileDescription, ProfileContacts, ContactLine, NumberTile, InlineField,
        Row,
        Column,
        LMap, LTileLayer, LMarker, LPopup,
        Tag,
        'v-marker-cluster': Vue2LeafletMarkerCluster
    }
})
export default class ClientRead extends Vue {
    $refs!: {
        any1: any,
        any2: any,
        any3: any,
        any4: any,
        map: any
    };

    @Prop() company!: ICompanyDB;
    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];
    @State('collaborators') collaborators!: IUserDB[];
    @State('configuration') configuration!: any;
    @Getter('isAgency') isAgency!: boolean;
    @Getter('isInteraction') isInteraction!: Boolean;

    capitalize = capitalize;
    companyMarkers: any = [];
    lines: any = [];
    descriptionSize = "";
    mapSize = "";
    apiUrl: string = <string>process.env.VUE_APP_API_URL?.replace('/1.0', '') + "/";
    currentView = 'informations';
    search: string = "";
    visibleInput: boolean = false;
    mergedAddresses: IAddress[] = [];

    url: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

    attribution: string = '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'

    get logo(): string {
        if (!this.company.logo) return '';
        if (this.company.logo.indexOf('base64') !== -1) {
            return this.company.logo;
        }
        return this.apiUrl + this.company.logo
    }

    center: IGeoLocation = { lat: 48.845617, lng: 2.2431133 };

    getBusinessSector(id: string) {
        return this.businessSectorOptions.find((i: LabelValue) => i.value === id);
    }

    get businessSectorName() {
        return this.getBusinessSector(this.company.businessSectorId)?.label;
    }

    get zoom() {
        return (this.mapMarkerPosition && 16) || 8;
    }

    get mapMarkerPosition() {
        return this.companyMarkers;
    }

    setCompanyMarker() {
        let mergedAddresses: IAddress[] = [];
        if (this.company?.addresses) mergedAddresses = [...this.company.addresses];
        if (this.company?.mainAddress) mergedAddresses.push(this.company.mainAddress);
        if (mergedAddresses.length === 0) return null;
        this.mergedAddresses = mergedAddresses;

        this.companyMarkers = mergedAddresses.filter(address => address.location?.length).map((address: IAddress) => {
            if (!address.location) return null;
            return {
                lat: parseFloat(address.location[1].toString()),
                lng: parseFloat(address.location[0].toString()),
            }
        })
    }

    companyAddress(address: IAddress) {
        return formattedAddress(address);
    }

    missionAdresses() {
        return this.mergedAddresses.map((addr) => formattedAddress(addr));
    }

    get theDescription() {
        return this.company.description?.replace(/\n/gm, '<br>');
    }

    get companyName() {
        return this.company.name;
    }

    get youtubeId() {
        if (this.company.video) {
            if (this.company.video.indexOf('youtube.com') !== -1) {
                if (this.company.video.indexOf("v=") !== -1) {
                    return this.company.video.split('v=')[1];
                }
                if (this.company.video.indexOf("/embed/") !== -1) {
                    return this.company.video.split('/embed/')[1];
                }
            }
            if (this.company.video.indexOf('youtu.be') !== -1) {
                return this.company.video.split('youtu.be/')[1]
            }
        }
    }

    get socialNetworks() {
        return this.company.socialNetworks || {};
    }

    get youtubeLink() {
        return this.company.video && ("https://www.youtube.com/embed/" + this.youtubeId);
    }

    get isInformations() {
        return this.currentView === 'informations';
    }

    get isDetails() {
        return this.currentView === 'details';
    }

    get filteredContacts() {
        const searchPattern: RegExp = new RegExp(this.search, 'i');
        return this.company.contacts.filter(contact => {
            return searchPattern.test(contact.firstname) || searchPattern.test(contact.lastname) || searchPattern.test(`${contact.firstname} ${contact.lastname}`) || searchPattern.test(contact.email) || searchPattern.test(contact.phone) || searchPattern.test(contact.role);
        });
    }

    getPictogram(businessSectorId: string) {
        const f = this.configuration.businessSectors.find((bs: IBusinessSectorDB) => bs._id === businessSectorId);
        return f?.pictogram;
    }

    setSize(r: any) {
        if (r.$refs?.map) {
            this.mapSize = r.$refs.map.$el.scrollHeight + 'px';
        }
        if (r.$refs?.description) {
            this.descriptionSize = r.$refs.description.$el.scrollHeight + 'px';
        }
    }

    setDescriptionSize() {
        if (this.company) {
            if (this.$refs.any1) {
                this.setSize(this.$refs.any1);
            }
            if (this.$refs.any2) {
                this.setSize(this.$refs.any2);
            }
            if (this.$refs.any3) {
                this.setSize(this.$refs.any3);
            }
            if (this.$refs.any4) {
                this.setSize(this.$refs.any4);
            }
        }
    }

    externalLink(link: string | undefined) {
        if (link) {
            if (link.indexOf('http://') === -1 && link.indexOf('https://') === -1) {
                link = "http://" + link;
            }
            window.open(link);
        }
    }

    setLines() {
        let lineOne: any = [];
        let lineTwo: any = [];
        let availablesObjects: string[] = [];

        if (this.company.collaborators?.length) {
            availablesObjects.push("Contacts")
        }
        if (this.theDescription) {
            availablesObjects.push("Description")
        }
        if (this.mapMarkerPosition) {
            availablesObjects.push("Map")
        }
        if (this.youtubeLink) {
            availablesObjects.push("Video")
        }

        availablesObjects.forEach((a: any, i: number) => {
            if (i > 1) {
                lineTwo.push(a);
            } else {
                lineOne.push(a);
            }
        });

        this.lines = [lineOne, lineTwo];
    }
    changeView(view: string) {
        this.currentView = view;
    }

    mounted() {
        setTimeout(() => {
            this.setDescriptionSize();
            this.setCompanyMarker()
        });
        this.setLines();
    }
}
