
import {Component, Vue, Watch} from 'vue-property-decorator';
import ClientForm from "@/components/client/ClientForm.vue";
import {IAddress, ICompanyDB, IMissionDB, StoreAction, StoreMutation, IAgencyDB} from "@/types";
import {Action, Getter, Mutation, State} from "vuex-class";
import {ROUTES} from "@/data";
import MissionTile from "@/components/tileContent/MissionTile.vue";
import ClientRead from "@/components/client/ClientRead.vue";
import clonedeep from "lodash.clonedeep";
import LockedAction from '@/components/modal/LockedAction.vue';
import { showModal } from '@/helpers/callables';

@Component({
    name: "Client",
    components: {ClientRead, MissionTile, ClientForm, LockedAction}
})
export default class Client extends Vue {
    $refs!: {
        container: any
    };
    errors:any = {};
    loading = false;
    showLockedAction = false;
    innerLoading = false;
    client!: ICompanyDB;
    tmpClient!: ICompanyDB;
    mode = "read";
    globalErrors:any = [];
    queryUsed = false;

    @Action('actions/loadCompany') loadCompany!: StoreAction;
    @Action('actions/companySynchronization') companySynchronization!: StoreAction;
    @Action('actions/editCompany') editCompany!: StoreAction;
    @Action('actions/createMission') createMission!: StoreAction;
    @Mutation('removeLink') removeLink!: StoreMutation;
    @State('currentAgency') currentAgency!: IAgencyDB;

    @Getter('isSupervisor') isSupervisor!: boolean;
    @Getter('isInteraction') isInteraction!: boolean
    @Getter('isInteractionLimited') isInteractionLimited!: boolean
    @Getter('isAuthorizedAgency') isAuthorizedAgency!: boolean
    @Getter('hasSalesforce') hasSalesforce!: boolean;

    @Watch('$route.params.companyId')
    onCompanyId() {
        this.init();
    }

    get isQueryEdit() {
        return this.$route.query.edit === '1';
    }

    get companyId() {
        return this.$route.params.companyId;
    }

    get isReadMode() {
        return this.mode === "read";
    }

    get isEditMode() {
        return this.mode === "edit";
    }

    get isSynchronizable() {
        return (this.isReadMode && this.isInteraction);
    }

    goEdition() {
        this.tmpClient = clonedeep(this.client);
        this.mode = 'edit';
    }

    quitEdition() {
        this.mode = 'read';
        this.$refs.container.$el.scrollTop = 0;
    }

    checkEmptyAddresses() {
        let empty = false;
        this.tmpClient?.addresses?.forEach((addr:IAddress) => {
            // if(!addr.location?.length || !addr.city) {
            if(!addr.city) {
                empty = true;
            }
        });
        return empty;
    }

    displaylock() {
        showModal.call(this, 'LockedAction');
    }

    clickAction(other:any) {
        this.isInteractionLimited ? this.displaylock() : other()
    }

    async mission() {
        this.loading = true;
        try {
            const mission: IMissionDB = await this.createMission({
                name: this.client.name,
                clientId: this.client._id
            });
            // localStorage.setItem('lastMissionId', mission._id);
            await this.$router.push({name: ROUTES.APP.MISSIONCREATION, query: {missionId: mission._id}});
        } catch (e) {
            console.log(e);
        }
        this.loading = false;
    }

    async synchronizeCompany() {
        try {
            const updatedCompany = await this.companySynchronization(this.companyId);
            console.log("synchronized company: ", updatedCompany);
            this.client = updatedCompany;
            this.$toast.open({
                position: "top-right",
                message: this.$t("synchronization.client.success").toString(),
                type: "success",
                duration: 10000
            });
            this.$forceUpdate();
        }
        catch (e) {
            console.log(e);
            this.$toast.open({
                position: "top-right",
                message: this.$t("errors.Client.synchronization._default").toString(),
                type: "error",
                duration: 10000
            });
        }
    }

    async update() {
        this.innerLoading = true;
        this.errors = {};
        this.globalErrors = [];
        try {
            if (this.checkEmptyAddresses()) {
                this.globalErrors = [{id: 'Address.Empty'}];
                this.innerLoading = false;
                return;
            }
            if(this.tmpClient.siret) this.tmpClient.siret = this.tmpClient.siret.replace(/\s/g, '');
            const result = await this.editCompany(this.tmpClient);
            this.client = result.company;
            console.log("RESULT: ", result);
            if(result.warning) {
                console.log("WARNING: A Afficher...", result.warning);
                this.$toast.open({
                    position: "top-right",
                    message: this.$t("errors.Client." + result.warning.context + "." + result.warning.id).toString(),
                    type: "warning",
                    duration: 10000
                });
            }
            this.tmpClient = clonedeep(this.client);
            this.quitEdition();
        } catch (e) {
            // if (e.fullErr?.response?.status === 422) {
            //     if (e.fullErr.response.data[0]["id"] == "Siret.NotValid") {
            //         this.globalErrors.push("Numéro Siret invalide: il doit contenir 14 chiffres sans espace ou resté vide.");
            //     }
            // }
            console.log(e);
            this.errors = e.errors;
            this.globalErrors = e._global;
        }
        this.innerLoading = false;
    }

    async init() {
        this.loading = true;
        try {
            this.client = await this.loadCompany(this.companyId);

            if (this.isQueryEdit && !this.queryUsed) {
                this.goEdition();
                this.queryUsed = true;
            }
        } catch (e) {
            this.errors = e.errors
            if (e?.fullErr?.response?.status === 404) {
                this.globalErrors.push({id: "Interim.NotFound"});
                await this.removeLink(this.companyId);
            }
            if (e?.fullErr?.response?.status === 500) {
                this.globalErrors.push({id: "Global.ServerError"});
                await this.removeLink(this.companyId);
            }
        }
        this.loading = false;
    }

    beforeMount() {
        this.init();
    }
}
